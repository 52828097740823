import React, {
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import style from './disclaimer.module.less';
import Logo from '../../assets/logo-white.png';
import { useOnClickOutside } from '../../hooks/use-on-click-outside';
import CloseIcon from '../../assets/icons/cross-circle.svg?react';

export interface DisclaimerModalRef {
  closeModal: () => void;
  openModal: () => void;
}

interface Props {
  onClose?: () => void;
}

export const DisclaimerModal = forwardRef<DisclaimerModalRef | null, Props>(
  (props, ref) => {
    const { onClose } = props;
    const { t } = useTranslation();
    const [open, setOpen] = useState(null);
    const modalRef = createRef<HTMLDivElement>();
    const closeModal = useCallback(() => {
      setOpen(false);
      onClose && onClose();
    }, [onClose]);
    const openModal = useCallback(() => setOpen(true), []);
    useOnClickOutside(modalRef, closeModal);

    useImperativeHandle(ref, () => ({
      closeModal,
      openModal,
    }));

    useEffect(() => {
      // stop scrolling when modal is open
      if (open) {
        document.body.style.overflow = 'hidden';
      } else if (open === false) {
        document.body.style.overflow = 'auto';
      }
    }, [open]);

    const body = useMemo(
      () => (
        <div className={style.modalWrapper}>
          <div className={style.modalBody} ref={modalRef}>
            <div className={style.headerWrapper}>
              <div className={style.closeIconContainer} onClick={closeModal}>
                <CloseIcon className={style.closeIcon} />
              </div>
              <div className={style.header}>
                <img src={'/standard-bg.jpg'} className={style.bgImage} />
                <div className={style.logoWrapper}>
                  <img src={Logo} className={style.logoImage} />
                </div>
              </div>
            </div>
            <div className={`${style.body} ${style.disclaimerBody}`}>
              <div
                className={style.bodyText}
                dangerouslySetInnerHTML={{
                  __html: t('auth.disclaimer'),
                }}
              />
              <div className={style.buttonWrapper}>
                <button className={style.actionButton} onClick={closeModal}>
                  {t('confirm.ok')}
                </button>
              </div>
            </div>
          </div>
        </div>
      ),
      [closeModal, modalRef, t]
    );

    return createPortal(open ? body : <></>, document.body);
  }
);

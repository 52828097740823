import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addLanguage } from '../i18n';

export const usePrematterTranslations = (prematterLanguage: string) => {
  const [wrappedLanguage, setWrappedLanguage] = useState<string | undefined>(
    undefined
  );

  const { t } = useTranslation(undefined, { lng: wrappedLanguage });

  useEffect(() => {
    if (prematterLanguage) {
      const asyncWrapper = async () => {
        const wrapped = await addLanguage(prematterLanguage);
        setWrappedLanguage(wrapped);
      };
      asyncWrapper();
    }
  }, [prematterLanguage]);

  return t;
};

import React, {
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import style from './style.module.less';
import { useOnClickOutside } from '../../hooks/use-on-click-outside';
import CloseIcon from '../../assets/icons/cross-circle.svg?react';
import ShieldIcon from '../../assets/icons/shield-tick.svg?react';
import BannerLeft from '../../assets/verify/banner-left.svg?react';
import BannerRight from '../../assets/verify/banner-right.svg?react';
import { usePrematterTranslations } from '../../hooks/use-prematter-translations';
import { RadioInput } from '@law-connect/react-components';

export interface VerifyModalRef {
  closeModal: () => void;
  openModal: () => void;
}

export enum VerifyModalType {
  MoreInfo = 'more-info',
  Pending = 'pending',
  Verify = 'verify',
}

interface Props {
  language: string;
  modalType: VerifyModalType;
  verifyAction?: () => void;
  onClose?: () => void;
  updateAnswer?: (answer: string) => void;
  verifyAnswer?: string;
}

export const VerifyModalComponent = forwardRef<VerifyModalRef | null, Props>(
  (props, ref) => {
    const {
      onClose,
      verifyAction,
      language,
      modalType,
      updateAnswer,
      verifyAnswer,
    } = props;
    const t = usePrematterTranslations(language);

    const [isOpen, setOpen] = useState(false);
    const modalRef = createRef<HTMLDivElement>();
    const closeModal = useCallback(() => {
      setOpen(false);
      onClose && onClose();
    }, [onClose]);
    const openModal = useCallback(() => setOpen(true), []);
    useOnClickOutside(modalRef, closeModal);

    useImperativeHandle(ref, () => ({
      closeModal,
      openModal,
    }));

    useEffect(() => {
      // stop scrolling when modal is open
      if (isOpen) {
        document.body.style.overflow = 'hidden';
      } else if (isOpen === false) {
        document.body.style.overflow = 'auto';
      }
    }, [isOpen]);

    const verifyActionWrapper = useCallback(() => {
      verifyAction && verifyAction();
    }, [verifyAction]);

    const body = useMemo(() => {
      return (
        <div className={style.modalWrapper}>
          <div className={style.modalBody} ref={modalRef}>
            <div className={style.headerWrapper}>
              <img src={'/standard-bg.jpg'} className={style.imageBg} />
              <div className={style.closeIconContainer} onClick={closeModal}>
                <CloseIcon className={style.closeIcon} />
              </div>
              <div className={style.imageBodyContainer}>
                <div className={style.shieldBubbleContainer}>
                  <ShieldIcon className={style.shieldBubble} />
                </div>
                <div className={style.imageBodyText}>{t('verify.verify')}</div>
                <div
                  className={style.verificationExplanation}
                  dangerouslySetInnerHTML={{
                    __html: t('verify.verification-explanation'),
                  }}
                />
              </div>
              <div className={style.bannerContainer}>
                <div className={style.bannerWrapper}>
                  <div className={style.bannerText}>
                    {t('verify.free-service')}
                  </div>
                  <BannerLeft className={style.bannerLeft} />
                  <BannerRight className={style.bannerRight} />
                </div>
              </div>
            </div>
            <VerifyBodyComponent
              modalType={modalType}
              language={language}
              updateAnswer={updateAnswer}
              verifyAnswer={verifyAnswer}
            />
            <VerifyFooterButtonComponent
              modalType={modalType}
              verifyActionWrapper={verifyActionWrapper}
              closeModal={closeModal}
              language={language}
            />
          </div>
        </div>
      );
    }, [
      modalRef,
      closeModal,
      t,
      modalType,
      language,
      updateAnswer,
      verifyAnswer,
      verifyActionWrapper,
    ]);

    return createPortal(isOpen ? body : <></>, document.body);
  }
);

const VerifyBodyComponent = (props: {
  modalType: VerifyModalType;
  language: string;
  verifyAnswer?: string;
  updateAnswer?: (answer: string) => void;
}) => {
  const { modalType, language, updateAnswer, verifyAnswer } = props;
  const t = usePrematterTranslations(language);

  if (modalType === VerifyModalType.Verify && updateAnswer) {
    return (
      <>
        <div className={`${style.section} ${style.verifyQuestionWrapper}`}>
          <VerifyLawyerQuestionComponent
            language={language}
            updateAnswer={updateAnswer}
            verifyAnswer={verifyAnswer}
          />
        </div>
      </>
    );
  } else if (modalType === VerifyModalType.MoreInfo) {
    return (
      <>
        <div className={style.section}>
          <div
            className={style.moreInfoText}
            dangerouslySetInnerHTML={{
              __html: t('verify.verify-more-info-body'),
            }}
          />
        </div>
      </>
    );
  } else if (modalType === VerifyModalType.Pending) {
    return (
      <>
        <div className={`${style.section} ${style.firstSection}`}>
          <div className={style.descriptionContainer}>
            <div className={style.description}>
              <span className={style.tick}>✓</span>
              {t('verify.description1')}
            </div>
            <div className={style.description}>
              <span className={style.tick}>✓</span>
              {t('verify.description2')}
            </div>
            <div className={style.description}>
              <span className={style.tick}>✓</span>
              {t('verify.description3')}
            </div>
            <div className={style.description}>
              <span className={style.tick}>✓</span>
              {t('verify.description4')}
            </div>
          </div>
        </div>
      </>
    );
  }
};

const VerifyFooterButtonComponent = (props: {
  modalType: VerifyModalType;
  verifyActionWrapper: () => void;
  closeModal: () => void;
  language: string;
}) => {
  const { modalType, verifyActionWrapper, closeModal, language } = props;
  const t = usePrematterTranslations(language);

  if (modalType === VerifyModalType.Verify) {
    return (
      <>
        <div className={style.section}>
          <div className={style.buttonWrapper}>
            <button
              className={style.verifyButton}
              onClick={verifyActionWrapper}
            >
              {t('verify.verify-now')}
            </button>
            <button className={style.button} onClick={closeModal}>
              {t('confirm.cancel')}
            </button>
          </div>
        </div>
      </>
    );
  } else if (modalType === VerifyModalType.Pending) {
    return (
      <>
        <div className={style.section}>
          <div className={style.pendingBody}>
            <div className={style.bodyText}>{t('verify.pending-text')}</div>
            <ShieldIcon className={style.shieldIcon} />
          </div>
        </div>

        <div className={style.section}>
          <div className={style.buttonWrapper}>
            <button className={style.button} onClick={closeModal}>
              {t('confirm.close')}
            </button>
          </div>
        </div>
      </>
    );
  } else if (modalType === VerifyModalType.MoreInfo) {
    return (
      <>
        <div className={style.section}>
          <div className={style.buttonWrapper}>
            <button className={style.button} onClick={closeModal}>
              {t('confirm.close')}
            </button>
          </div>
        </div>
      </>
    );
  }
};

interface VerifyLawyerQuestionComponentProps {
  language: string;
  verifyAnswer?: string;
  updateAnswer: (answer: string) => void;
}

export const VerifyLawyerQuestionComponent = (
  props: VerifyLawyerQuestionComponentProps
) => {
  const { language, updateAnswer, verifyAnswer } = props;
  const t = usePrematterTranslations(language);
  const onClick = useCallback(
    (answer: string) => {
      updateAnswer(answer);
    },
    [updateAnswer]
  );
  useEffect(() => {
    updateAnswer(t('verify.lawyer-answer-1'));
  }, [t, updateAnswer]);

  return (
    <div className={style.verifyQuestionContainer}>
      <div
        className={style.needLawyerQuestion}
        dangerouslySetInnerHTML={{
          __html: t('verify.lawyer-question'),
        }}
      />
      <div className={style.lawyerOptionsContainer}>
        <RadioInput
          label={t('verify.lawyer-answer-1')}
          value={verifyAnswer}
          name='lawyer-option1'
          onChange={() => onClick(t('verify.lawyer-answer-1'))}
          checked={
            verifyAnswer == null || verifyAnswer === t('verify.lawyer-answer-1')
          }
        />
        <RadioInput
          label={t('verify.lawyer-answer-2')}
          value={verifyAnswer}
          name='lawyer-option2'
          onChange={() => onClick(t('verify.lawyer-answer-2'))}
          checked={verifyAnswer === t('verify.lawyer-answer-2')}
        />
        <RadioInput
          label={t('verify.lawyer-answer-3')}
          value={verifyAnswer}
          name='lawyer-option3'
          onChange={() => onClick(t('verify.lawyer-answer-3'))}
          checked={verifyAnswer === t('verify.lawyer-answer-3')}
        />
        <RadioInput
          label={t('verify.lawyer-answer-4')}
          value={verifyAnswer}
          name='lawyer-option4'
          onChange={() => onClick(t('verify.lawyer-answer-4'))}
          checked={verifyAnswer === t('verify.lawyer-answer-4')}
        />
      </div>
      <div
        className={style.verifyDisclaimer}
        dangerouslySetInnerHTML={{
          __html: t('verify.verify-disclaimer'),
        }}
      />
    </div>
  );
};

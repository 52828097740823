import React, { FC } from 'react';
import Markdown from 'react-markdown';
import { LibraryCase } from '../../../types/library';
import style from './style.module.less';
import ShieldTickIcon from '../../../assets/icons/shield-tick.svg?react';
import { useTranslation } from 'react-i18next';

interface Props {
  case: LibraryCase;
}

export const LibraryTextWrapper: FC<Props> = ({ case: libraryCase }) => {
  const { t } = useTranslation();
  return (
    <div className={style.container}>
      <div className={style.textWrapper}>
        <div
          className={style.description}
          dangerouslySetInnerHTML={{
            __html: libraryCase.description,
          }}
        />
        <div className={style.questionsContainer}>
          <div className={style.questionsTitle}>
            {t('library.questions-about-case')}
          </div>
          <div className={style.verifiedBanner}>
            <ShieldTickIcon className={style.verifiedIcon} />
            <span>{t('library.verified-answers')}</span>
          </div>
          <div className={style.questions}>
            {libraryCase.questions.map((q, i) => (
              <div key={i} className={style.questionContainer}>
                <div className={style.questionTitle}
                  dangerouslySetInnerHTML={{
                    __html: q.question,
                  }}
                />
                <div
                  className={style.answer}
                  dangerouslySetInnerHTML={{
                    __html: q.verifiedAnswer.replace(/\*\*/g, ''),
                    // .replace(/(<br>)+/g, '<br />'),
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

import React, {
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import style from './share.module.less';
import Logo from '../../assets/logo-white.png';
import { useOnClickOutside } from '../../hooks/use-on-click-outside';
import CloseIcon from '../../assets/icons/cross-circle.svg?react';
import { toast } from 'react-toastify';
import LinkIcon from '../../assets/icons/link.svg?react';

import FaceBookIcon from '../../assets/icons/social/share-facebook.svg?react';
import LinkedInIcon from '../../assets/icons/social/share-linkedin.svg?react';
import RedditIcon from '../../assets/icons/social/share-reddit.svg?react';
import ThreadsIcon from '../../assets/icons/social/share-threads.svg?react';
import XIcon from '../../assets/icons/social/share-x.svg?react';

export interface ShareModalRef {
  closeModal: () => void;
  openModal: () => void;
}

interface Props {
  shareUrl: string;
  onClose?: () => void;
}

export const ShareModal = forwardRef<ShareModalRef | null, Props>(
  (props, ref) => {
    const { onClose, shareUrl } = props;
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const modalRef = createRef<HTMLDivElement>();
    const closeModal = useCallback(() => {
      setOpen(false);
      onClose && onClose();
    }, [onClose]);
    const openModal = useCallback(() => setOpen(true), []);
    useOnClickOutside(modalRef, closeModal);

    const SOCIAL_MEDIA_CLICK = useMemo(
      () => [
        {
          icon: FaceBookIcon,
          name: 'Facebook',
          url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            shareUrl
          )}`,
        },
        {
          icon: XIcon,
          name: 'X',
          url: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
            shareUrl
          )}`,
        },
        {
          icon: LinkedInIcon,
          name: 'LinkedIn',
          url: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
            shareUrl
          )}`,
        },
        {
          icon: RedditIcon,
          name: 'Reddit',
          url: `https://www.reddit.com/submit?url=${encodeURIComponent(
            shareUrl
          )}`,
        },
        {
          icon: ThreadsIcon,
          name: 'Threads',
          url: `https://www.threads.net/intent/post?url=${encodeURIComponent(
            shareUrl
          )}`,
        },
      ],
      [shareUrl]
    );

    useImperativeHandle(ref, () => ({
      closeModal,
      openModal,
    }));

    const copyShareUrl = useCallback(() => {
      toast.success('Link copied', {
        icon: <LinkIcon className={style.toastLinkIcon} />,
      });
      navigator.clipboard.writeText(shareUrl);
    }, [shareUrl]);

    useEffect(() => {
      // stop scrolling when modal is open
      if (open) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }, [open]);

    const body = useMemo(
      () => (
        <div className={style.modalWrapper}>
          <div className={style.modalBody} ref={modalRef}>
            <div className={style.headerWrapper}>
              <div className={style.closeIconContainer} onClick={closeModal}>
                <CloseIcon className={style.closeIcon} />
              </div>
              <div className={style.header}>
                <img src={'/standard-bg.jpg'} className={style.bgImage} />
                <div className={style.logoWrapper}>
                  <img src={Logo} className={style.logoImage} />
                </div>
                <div className={style.headerDescription}>
                  Want to check the answers with someone? Share your case
                  around!
                </div>
              </div>
            </div>
            <div className={style.shareBody}>
              <div className={style.bodyWrapper}>
                <div className={style.urlLabel}>
                  <span className={style.labelText}>URL</span>
                  <input
                    type='text'
                    className={style.urlInput}
                    readOnly
                    value={shareUrl}
                    onClick={copyShareUrl}
                  />
                </div>
                <div className={style.linkButtonContainer}>
                  <button className={style.linkButton} onClick={copyShareUrl}>
                    <LinkIcon className={style.linkIcon} />
                    <span>Copy link</span>
                  </button>
                </div>
              </div>
              <div className={style.otherWaysContainer}>
                <span className={`${style.labelText} ${style.otherWaysLabel}`}>
                  Other ways
                </span>
                <div className={style.otherWaysButtonsContainer}>
                  <div className={style.otherWaysButtonsWrapper}>
                    {SOCIAL_MEDIA_CLICK.map((item) => (
                      <a
                        href={item.url}
                        target='_blank'
                        className={style.otherWaysButton}
                        key={item.name}
                      >
                        <item.icon />
                        <div className={style.otherWaysText}>{item.name}</div>
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
      [modalRef, closeModal, shareUrl, copyShareUrl, SOCIAL_MEDIA_CLICK]
    );

    return createPortal(open ? body : <></>, document.body);
  }
);

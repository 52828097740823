import React, {
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { useOnClickOutside } from '../../hooks/use-on-click-outside';
import Logo from '../../assets/logo-white.png';
import CloseIcon from '../../assets/icons/cross-circle.svg?react';
import { AuthModalType } from './constants';
import style from './auth.module.less';
import { OnOffInput } from '@law-connect/react-components';
import ShieldTickIcon from '../../assets/icons/shield-tick.svg?react';
import { VerifyLawyerQuestionComponent } from '../verify-modal';
export type AuthModalRef = {
  closeModal: () => void;
  openModal: () => void;
  setShowVerifiedToggle: (show: boolean) => void;
};

interface Props {
  type?: AuthModalType;
  onClose?: () => void;
  redirectTo?: string;
  // required for verify modal
  language?: string;
  showVerified?: boolean;
  lawyerAnswer?: string;
  onLawyerAnswerChange?: (answer: string) => void;
}

export const AuthModal = forwardRef<AuthModalRef | null, Props>(
  (props, ref) => {
    const {
      onClose,
      lawyerAnswer,
      onLawyerAnswerChange,
      showVerified,
      language,
    } = props;
    const { t } = useTranslation();
    const { redirectTo, type = AuthModalType.AUTH } = props;
    const { login, register, isAuthenticated } = useKindeAuth();
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(null);
    const [showVerifiedToggle, setShowVerifiedToggle] = useState(false);

    const modalRef = createRef<HTMLDivElement>();
    const closeModal = useCallback(() => {
      onClose?.();
      setIsOpen(false);
    }, [onClose]);

    const openModal = useCallback(() => {
      setIsOpen(true);
    }, []);
    useOnClickOutside(modalRef, closeModal);

    useImperativeHandle(ref, () => ({
      closeModal,
      openModal,
      setShowVerifiedToggle,
    }));

    useEffect(() => {
      // stop scrolling when modal is isOpen
      if (isOpen) {
        document.body.style.overflow = 'hidden';
      } else if (isOpen === false) {
        document.body.style.overflow = 'auto';
      }
    }, [isOpen]);

    const loginRedirect = useCallback(() => {
      login({
        // eslint-disable-next-line camelcase
        app_state: {
          redirectTo:
            redirectTo || location.state?.from?.pathname || '/account',
        },
      });
    }, [location.state?.from?.pathname, login, redirectTo]);

    const registerRedirect = useCallback(() => {
      register({
        // eslint-disable-next-line camelcase
        app_state: {
          redirectTo:
            redirectTo || location.state?.from?.pathname || '/account',
        },
      });
    }, [register, redirectTo, location.state?.from?.pathname]);

    const body = useMemo(
      () => (
        <div className={style.modalWrapper}>
          <div className={style.modalBody} ref={modalRef}>
            <div className={style.headerWrapper}>
              <div className={style.closeIconContainer} onClick={closeModal}>
                <CloseIcon className={style.closeIcon} />
              </div>
              <div className={style.header}>
                <img
                  src={`${
                    type === AuthModalType.BOT_AUTH
                      ? '/auth/bot.png'
                      : '/standard-bg.jpg'
                  }`}
                  className={style.bgImage}
                />
                <div className={style.logoWrapper}>
                  <img src={Logo} className={style.logoImage} />
                </div>
                <div
                  className={style.headerDescription}
                  dangerouslySetInnerHTML={{
                    __html:
                      type === AuthModalType.REPORT
                        ? t('auth.modal.report-description')
                        : t('auth.modal.description'),
                  }}
                />
              </div>
            </div>
            {/* on show verify we want to append a verify toggle  */}
            {showVerified ? (
              <>
                <div className={style.verifyToggleContainer}>
                  <div className={style.verifyToggleRow}>
                    <div className={style.verifyToggleDescription}>
                      <ShieldTickIcon className={style.verifyToggleIcon} />
                      <span>{t('verify.verify-for-free')}</span>
                    </div>
                    <div className={style.verifyToggle}>
                      <OnOffInput
                        title={
                          showVerifiedToggle
                            ? t('confirm.yes')
                            : t('confirm.no')
                        }
                        onChange={setShowVerifiedToggle}
                        value={showVerifiedToggle}
                        hideLabel={true}
                        className={style.verifyToggleInput}
                        titleClassName={style.verifyToggleTitle}
                        switchButtonClassName={style.verifyToggleSwitch}
                      />
                    </div>
                  </div>
                  {showVerifiedToggle && (
                    <div className={style.verifyQuestionWrapper}>
                      <VerifyLawyerQuestionComponent
                        language={language}
                        updateAnswer={onLawyerAnswerChange}
                        verifyAnswer={lawyerAnswer}
                      />
                    </div>
                  )}
                </div>
              </>
            ) : null}
            <div className={style.body}>
              <div className={style.buttonWrapper}>
                <button
                  className={style.actionButton}
                  onClick={registerRedirect}
                >
                  {t('auth.modal.create-account')}
                </button>
                <button className={style.actionButton} onClick={loginRedirect}>
                  {t('auth.modal.sign-in')}
                </button>
              </div>

              <div
                className={style.authText}
                dangerouslySetInnerHTML={{ __html: t('auth.modal.footer') }}
              />
            </div>
          </div>
        </div>
      ),
      [
        closeModal,
        language,
        lawyerAnswer,
        loginRedirect,
        modalRef,
        onLawyerAnswerChange,
        registerRedirect,
        showVerified,
        showVerifiedToggle,
        t,
        type,
      ]
    );

    return createPortal(
      !isAuthenticated && isOpen ? body : <></>,
      document.body
    );
  }
);
